import React from 'react'
import Layout from '../components/Layout/Layout'
import Sidebar from '../components/Sidebar/Sidebar'
import Seo from '../components/Seo/Seo'
import {Container, Row, Col} from 'react-bootstrap'
import GalleryGrid from '../components/GalleryGrid/GalleryGrid'
import { graphql } from 'gatsby'
import '../styles/templates/service-page-template.scss'
import StairCalculator from '../components/StairCalculator/StairCalculator'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import IndexForm from '../components/IndexForm/IndexForm'

function ServicePageTemplate({data, location, pageContext}) {    
      const {
        breadcrumb: { crumbs },
      } = pageContext
    crumbs[crumbs.length - 2].crumbLabel = 'Услуги'
    

    // function generateComponent(component) {
    //   let result;
    //   if (component) {
    //     switch(component) {
    //       case 'StairCalculator':  
    //         result = <StairCalculator/>;
    //         break;
    //     }
    //   }
    //   return result;
    // }

    function stairPage() {
      return (
          <>
          <StairCalculator/> 
          <IndexForm isWhite={true} mail={data.contentfulCompanyContacts.mail}/>
          </>
      )
  }

    const title = data.contentfulServicePage.title;
    const body = data.contentfulServicePage.body.childMarkdownRemark.html;
    const sectionNavigation = data.contentfulServicePage.pageContent;
    // const sectionSubNavigation = data.contentfulServicePage.pageContent.subSection;
    const otherSections = data.allContentfulServicePage.nodes;
    const tags = data.contentfulServicePage.tags;

    const meta = data.contentfulServicePage.meta

    return (
      <Layout>
        <Seo 
          title = {meta ? meta.title : title} 
          description = {meta ? meta.description : data.contentfulServicePage.body}
          keywords = {meta ? meta.keywords : undefined}
          image = {meta ? (meta.image.file.url ?  meta.image.file.url : undefined) : undefined}
        />

        <Container className="content service-page" fluid={'xl'}>
          <Row className="section-with-sidebar">
            <Sidebar 
              sectionNavigation={sectionNavigation}
              // sectionSubNavigation = {sectionSubNavigation}
              otherSections={otherSections}
              currentPage={data.currentPage.slug}
              currentSection='services'
            />

            <Col className="page-with-sidebar" xs={12} md={8}  lg={9}>
              <Breadcrumb
                crumbs={crumbs}
                crumbLabel={title}
              />
              <section className="service-page--content">
                <h1 className="service-page--title">{title}</h1>
                <div 
                  className='service-page--body service-page--content service-page--section' 
                  dangerouslySetInnerHTML={{__html: body}}
                />
                {
                        tags ?
                        <GalleryGrid 
                            page='service'
                            items = {data.allContentfulProject.nodes}
                            activeTag = {tags}
                        /> :
                        ''
                }
              </section>
            </Col>
            </Row>
        </Container>
        {crumbs[crumbs.length - 1].pathname === '/services/stairs' ? stairPage() : <IndexForm/>}
      </Layout>
  )
}

export default ServicePageTemplate

export const servicePageBySlug = graphql`
  query ServicePageBySlug($slug: String!) {
    contentfulServicePage(slug: {eq: $slug}) {
        title
        body {
          body
          childMarkdownRemark {
            html
          }
        }
        pageContent {
          title
          slug
          subSection {
          title
          slug
        }
        }
        tags {
          tag
        }
        component
        meta {
          title
          description
          keywords
          image {
            file {
              url
            }
          }
        }
     }

    allContentfulServicePage {
      nodes {
        title
        slug
      }
    }

     allContentfulProject(sort: {fields: number, order: DESC}) {
      nodes {
        title
        number
        tags {
          tag
        }
        heroImage {
          title
          fluid(maxWidth: 1000) {
            ...GatsbyContentfulFluid
          }
          file {
            url
          }
        }
        images {
          file {
            url
          }
          title
        }
        
      }
    }
    contentfulCompanyContacts(title: {eq: "Дерево Желаний"}) {
       mail
    }

    currentPage: contentfulServicePage(slug: {eq: $slug}) {
            slug
        }
  }
`
